import axios from "axios";
const apiService = process.env.REACT_APP_API;


axios.defaults.headers.post['Content-Type'] = 'application/json';
// axios.defaults.headers.common['ngrok-skip-browser-warning'] = '69420';
const api = axios.create({
    baseURL: apiService,
    // baseURL: 'http://10.111.70.6:8091',
    // baseURL: 'http://localhost:80',

});

export default api;
