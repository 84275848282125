import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { selectRedesp, getTransp} from '../../store/Transportadora';
import Select, {components} from 'react-select';

const { ValueContainer, Placeholder } = components;

const SelectRedespacho = ({initialValue}) => {
  const { optionsSelect } = useSelector(state => state.transpReduce)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getTransp())
    if(initialValue){ 
      dispatch(selectRedesp(initialValue))
    }
  },[dispatch, initialValue]);

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

  return(
    <div className="w-full">
      <Select
        defaultValue={optionsSelect[optionsSelect.findIndex((element) => element.value === initialValue)]}
        onChange={(e) => e === null ? dispatch(selectRedesp()) : dispatch(selectRedesp(e.value))}//dispatch(selectRedesp(e.value))}
        isClearable={true}
        isSearchable={true}
        options={optionsSelect}
        components={{ ValueContainer: CustomValueContainer }}
        placeholder="Redespacho"
        styles={{
          container: (provided, state) => ({
            ...provided,
            marginTop: 10
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible",
            marginTop: 20
          }),
          placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            top: -15 ,
            backgroundColor: "transparent",
            fontSize: 14
          })
        }}
      />
    </div>
  );
}

export default SelectRedespacho;