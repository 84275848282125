import { createSlice } from "@reduxjs/toolkit";
import api from "../services/api";
import {jwtDecode} from "jwt-decode";

// Função auxiliar para acessar localStorage de forma segura
const getFromLocalStorage = (key, defaultValue) => {
  try {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : defaultValue;
  } catch (error) {
    console.error(`Error reading ${key} from localStorage: `, error);
    return defaultValue;
  }
};

// Estado inicial utilizando a função auxiliar
const initialState = {
  dataUser: getFromLocalStorage("dataUser", []),
  token: getFromLocalStorage("Token", {}),
  selectUser: getFromLocalStorage("selectUser", {}),
  erroMassage: "",
  optionsSelect: [],
  isLoading: false,
};

const userSlice = createSlice({
  name: "usuario",
  initialState,
  reducers: {
    fetchUser: (state, action) => {
      const { user, token } = action.payload;
      state.dataUser = user;
      state.token = token;
      state.selectUser = user[0] || {};

      // Atualiza localStorage
      localStorage.setItem("dataUser", JSON.stringify(user));
      localStorage.setItem("Token", JSON.stringify(token));
      localStorage.setItem("selectUser", JSON.stringify(user[0]));

      // Atualiza opções de seleção
      state.optionsSelect = user.map((item) => ({
        label: item.nmVendedor,
        value: item.idVendedor,
      }));
    },
    fetchOptUser: (state, action) => {
      const { user } = action.payload;
      state.dataUser = user;
      localStorage.setItem("dataUser", JSON.stringify(user));

      // Atualiza opções de seleção
      state.optionsSelect = user.map((item) => ({
        label: item.nmVendedor,
        value: item.idVendedor,
      }));
    },
    setUser: (state, action) => {
      state.selectUser = action.payload;
      localStorage.setItem("selectUser", JSON.stringify(action.payload));
    },
    addUser: (state, action) => {
      state.dataUser.push(action.payload);
      // Se necessário, persistir dataUser no localStorage
    },
    editUser: (state, action) => {
      const index = state.dataUser.findIndex(
        (user) => user.idVendedor === action.payload.idVendedor
      );
      if (index >= 0) {
        state.dataUser[index] = action.payload;
        // Se necessário, persistir dataUser no localStorage
      }
    },
    delUser: (state) => {
      state.dataUser = [];
      state.token = {};
      state.selectUser = {};
      state.optionsSelect = [];

      // Limpa localStorage
      localStorage.removeItem("dataUser");
      localStorage.removeItem("Token");
      localStorage.removeItem("selectUser");
    },
    setErroMassage: (state, action) => {
      state.erroMassage = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  fetchUser,
  fetchOptUser,
  setUser,
  delUser,
  setErroMassage,
  setLoading,
} = userSlice.actions;

export default userSlice.reducer;

///////////////////////////  Actions ///////////////////////////////

export const getUser = (form) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    // Tenta obter o token
    const response = await api.get("/auth/token", { params: form });

    // Verifica se a resposta contém dados e se o status é 200 (sucesso)
    if (response?.data && response.status === 200) {
      const token = response.data;
      dispatch(fetchUser({ token, user: [] }));

      try {
        // Decodifica o token para extrair informações do usuário
        const tokenDecoded = jwtDecode(token.access_token);

        if (!tokenDecoded || !tokenDecoded.userid) {
          throw new Error("Token inválido ou não contém userid.");
        }

        // Busca os vendedores associados ao idUsuario extraído do token
        const userResponse = await api.get("/vendedores", {
          headers: {
            Authorization: `Bearer ${token.access_token}`,
          },
          params: { idUsuario: tokenDecoded.userid },
        });

        if (userResponse?.data && userResponse.data.length > 0) {
          dispatch(fetchUser({ token, user: userResponse.data }));
          dispatch(setErroMassage(""));
        } else {
          // throw new Error("Nenhum usuário encontrado.");
          dispatch(setErroMassage("Nenhum usuário encontrado."));
          // console.log("Nenhum usuário encontrado.1")
        }

      } catch (jwtError) {
        console.error("Erro ao decodificar o token JWT: ", jwtError);
        dispatch(setErroMassage("Falha ao decodificar as credenciais."));
      }

    } else {
      throw new Error("Erro na obtenção do token. Verifique as credenciais.");
    }

  } catch (apiError) {
    // console.error("Erro na autenticação ou busca de usuário: ", apiError);

    // Define mensagens de erro mais específicas
    if (apiError.response?.status === 401) {
      dispatch(setErroMassage("Credenciais inválidas. Tente novamente."));
    } else if (apiError.response?.status === 403) {
      dispatch(setErroMassage("Acesso negado. Permissões insuficientes."));
    } else if (apiError.response?.status === 500) {
      dispatch(setErroMassage("Erro interno no servidor. Tente mais tarde."));
    } else {
      dispatch(setErroMassage("Credenciais inválidas. Tente novamente."));
    }
  } finally {
    dispatch(setLoading(false));
  }
};


export const getOptVendedor = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const userResp = await api.get("/vendedores", {
      headers: { Authorization: `Bearer ${checkToken()}` },
      params: { filter: "nmVendedor" },
    });

    dispatch(fetchOptUser({ user: userResp.data }));
  } catch (err) {
    console.error("Error fetching options: ", err);
  } finally {
    dispatch(setLoading(false));
  }
};

export const selectVendedor = (params) => async (dispatch) => {
  try {
    const { idVendedor, nmVendedor, idErpUser } = params;
    const response = await api.get("/vendedores", {
      headers: { Authorization: `Bearer ${checkToken()}` },
      params: { idVendedor, nmVendedor, idErpUser, filter: "nmVendedor" },
    });

    dispatch(setUser(response.data[0]));
  } catch (e) {
    console.error("Error selecting vendor: ", e);
  }
};

export const logoutUser = () => (dispatch) => {
  dispatch(delUser());
};

export const checkToken = () => {
  const token = getFromLocalStorage("Token", {});
  return token.access_token;
};
