import { useEffect } from 'react';
import { Navbar, Offcanvas, Nav, NavDropdown, Container } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { selectFilial, getFilial } from '../../store/Filiais';
import SelectVendedor from '../Vendedor/SelectVendedor';
import { logoutUser } from '../../store/Usuarios';
const logo = process.env.REACT_APP_LOGO;
const name = process.env.REACT_APP_NAME;


function Sidebar() {

  const { dataFilial, select } = useSelector(state => state.filialReduce)
  const { dataUser, selectUser } = useSelector(state => state.userReduce)

  const dispatch = useDispatch()
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getFilial())
  }, [dispatch]);


  const degrade = {
    borderBottom: '2px solid transparent',
    borderImage: 'linear-gradient(to right, #f7941e, #000000)',
    borderImageSlice: 1
  };
  // Componente reutilizável com efeito hover
  const HoverNavLink = ({ to, children }) => {
    const handleMouseOver = (e) => {
      e.target.style.color = '#f7941e';
      e.target.style.textDecoration = 'underline';
    };

    const handleMouseOut = (e) => {
      e.target.style.color = 'rgba(0, 0, 0, 0.55)';
      e.target.style.textDecoration = 'none';
    };

    return (
      <Nav.Link
        eventKey={to}
        style={{
          display: 'flex',
          alignItems: 'center',
          transition: 'color 0.3s ease',

        }}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        {children}
      </Nav.Link>
    );
  };  

  const logout = () => {
    dispatch(logoutUser())
    navigate('/')
  };

  return (
    <div>
      {[false].map((expand) => (
        <Navbar key={expand} expand="lg" collapseOnSelect className="bg-body-tertiary p-0" style={degrade}>
          <Container>
            <Navbar.Brand>
              <img alt='Logo' src={logo} width="175" height="40"></img>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto" onSelect={(select) => navigate(select)}>
                <HoverNavLink to="/orcamentos">Cotações</HoverNavLink>
              </Nav>
              <Nav >
                <Nav className="me-auto">
                  {dataUser.length > 1 ? null :  
                    <HoverNavLink> {selectUser.nmVendedor}</HoverNavLink>
                  }
                </Nav>
                <Nav className="me-auto" onSelect={() => logout()}>
                  <HoverNavLink to="/">Sair</HoverNavLink>
                </Nav>

              </Nav>
            </Navbar.Collapse>
          </Container>

        </Navbar>

      ))}
      <NavDropdown
        // title={`${select.nmFilial}`}
        title={<b>{`${select.nmFilial}`}</b>}
        // id={`offcanvasNavbarDropdown-expand-${expand}`}
        className='p-3  ms-auto d-flex justify-content-end'
      >
        {dataFilial.map((item) => { return (<NavDropdown.Item key={item.idFilial} onClick={() => dispatch(selectFilial(item))}><b>{item.nmFilial}</b></NavDropdown.Item>) })}
      </NavDropdown>

    </div>
  );
}

export default Sidebar;