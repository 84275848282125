// import React, { useEffect, useState } from "react";
import React, { useEffect, useState , Component} from 'react';
import { Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { selectCliente, getCliente} from '../../store/Clientes';
import Select, {components} from 'react-select';
const { ValueContainer, Placeholder } = components;


const SelectCliente = ({codVendedor}) => {
  const { dataCliente, selectCli, optionsSelect } = useSelector(state => state.clienteReduce)
  const { dataUser, selectUser } = useSelector(state => state.userReduce)
  const [selectedValue, setSelectedValue] = useState(null); // State to hold the selected value
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCliente(codVendedor))
    setSelectedValue(null)
  },[dispatch,selectUser]);


  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      dispatch(selectCliente(selectedOption.value, ''));
      setSelectedValue(selectedOption); // Update selected value in state
      // Store selected value in local storage
    }
  };

  return(
    // <div className="flex flex-row bg-slate-200 w-full">
      <div className="w-full">
          <Select
            onChange={handleSelectChange}
            isClearable={true}
            isSearchable={true}
            options={optionsSelect}
            value={selectedValue} // Set selected value
            components={{
              ValueContainer: CustomValueContainer
            }}
            placeholder="Cliente"
            styles={{
              container: (provided, state) => ({
                ...provided,
                marginTop: 10
              }),
              valueContainer: (provided, state) => ({
                ...provided,
                overflow: "visible",
                marginTop: 20
              }),
              placeholder: (provided, state) => ({
                ...provided,
                position: "absolute",
                // top: state.hasValue || state.selectProps.inputValue ? -3 : "50%",
                top: -15 ,

                // backgroundColor:
                //   state.hasValue || state.selectProps.inputValue
                //     ? "white"
                //     : "transparent",
                backgroundColor: "transparent",
                // transition: "top 0.1s, font-size 0.1s",
                fontSize: 14
              })
            }}
          />
      </div>
    // </div>
  )
}


export default SelectCliente;