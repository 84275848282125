///////////////// HOOKS //////////////
import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
///////////////// STORE //////////////
import { selectCliente } from '../../store/Clientes';
import { postOrcamento } from '../../store/Orcamentos';
import { getDispProd } from '../../store/produtos';
import { postCarrinho, getCarrinho } from '../../store/Carrinho';
import { getCondpag, selectCondPag } from '../../store/CondPagamento';
import { checkToken } from '../../store/Usuarios';
import { getTabPreco, selectTabPreco } from '../../store/Preco';
import { getTransp, selectTransp, selectTipoTransp } from '../../store/Transportadora';
// import { getDispProd, getDispEst } from '../../store/Disponibilidade';
import api from "../../services/api";
// import { postCarrinho } from '../../store/Carrinho';
///////////////// COMPONENTS //////////////
import { Accordion, Button, Modal, Form, Row, Col, Dropdown, FloatingLabel } from 'react-bootstrap';
import { MdOutlineShoppingCart } from "react-icons/md";
import { useParams, useLocation } from 'react-router-dom';
import ViewCarrinho from './carrinho/ViewCarrinho'
import AddCarrinho from './carrinho/AddCarrinho'
import SelectCliente from '../../components/Clientes/SelectCliente'
import SelectTransportadora from '../../components/Transportadoras/SelectTransportadora'
import SelectRedespacho from '../../components/Transportadoras/SelectRedespacho'
import SelectCondPagamento from '../../components/CondPagamentos/SelectCondPagamento'
import SelectVendedor from '../../components/Vendedor/SelectVendedor';
import { NumericFormat } from 'react-number-format';
import {convertDecimal} from '../../function/maskInputMoney';
import { IoCubeOutline, IoAdd, IoRemove } from "react-icons/io5";
const estoque = process.env.REACT_APP_ESTOQUE;

// 6461977603          
const IncluirOrcamento = () => {
    const dispatch = useDispatch()
    const { initialProd, initialCor, initialDisp, page, hasNext } = useSelector(state => state.prodReduce)
    const { dataCliente, selectCli } = useSelector(state => state.clienteReduce)
    const { select } = useSelector(state => state.filialReduce)
    const { dataCarrinho } = useSelector(state => state.carrinhoReduce)
    const { dataUser, selectUser } = useSelector(state => state.userReduce)
    const { dataTransp } = useSelector(state => state.transpReduce)
    const { dataCondPag } = useSelector(state => state.condPagReduce)
    const { dataTabPreco, selectTabelaPreco } = useSelector(state => state.precoReduce)
    const [modalShow, setModalShow] = useState(false);
    const [modalShow1, setModalShow1] = useState(false);
    const [alertShow, setAlertShow] = useState(false);
    const [prodModal, setProdModal] = useState([]);
    const [condPagamento, setCondPagamento] = useState("")
    const [tabPreco, setTabPreco] = useState("")
    const [cor, setCor] = useState([]);
    const [observacao, setObservacao] = useState("");
    const [pedCliente, setPedCliente] = useState("");
    const [typeSearch, settypeSearch] = useState({ tittle: "Descrição", type: 2, term: "" })
    // const { state } = useLocation();
    // const {idCliente, dataOrcamento} = state
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            dispatch(selectCliente(id, ''))
        }
        dispatch(getDispProd(select.idFilial, typeSearch, page))
        // if(selectCli) {
        dispatch(getCarrinho())
        // }
        setProdModal(false)
        // dispatch(getCondpag())
        dispatch(getTabPreco())
        // dispatch(getTransp())
    }, [select, page, typeSearch.type]);

    const searchTerm = (term, page) => {
        settypeSearch({ ...typeSearch, term })
        dispatch(getDispProd(select.idFilial, { ...typeSearch, term: term.toUpperCase() }, page))
    }

    const TotalCarrinho = () => {

        let result = 0
        dataCarrinho.map((item) => {
            result = result + (((item.preco * item.quantidade ) - item.desconto)*(item.ipi/100 + 1))
        })
        // if (result % 1 === 0){
        //      result = `${result},00`
        // }

        return (
            <div className="flex w-full justify-end">
                <span className="font-bold">Total: R$ {convertDecimal(result.toFixed(2))}</span>
            </div>)
    }

    //////////////////Ajusta Filial //////////////
    const getCor = (codfilial, codprod) => {
        const params = new URLSearchParams([['filial', codfilial], ['codProduto', codprod]]);
        api
            .get(`/produtos/cores`, {headers:{Authorization:`Bearer ${checkToken()}`}, params })
            .then((response) => { setCor(response.data) })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }

    const ItemCores = ({ cores }) => {
        return (
            <tbody key={cores.idErpProduto + 'C'}>
                <tr style={cores.qtd > 0 ?  {} : { background: "#edeff0"}}>
                    <td style={{ border: "1px solid #dee2e6", textAlign: "center", padding: "0.75rem" }}>{cores.codCor}</td>
                    <td style={{ border: "1px solid #dee2e6", textAlign: "center", padding: "0.75rem" }}>{cores.nmCor}</td>
                    <td style={{ border: "1px solid #dee2e6", textAlign: "center", padding: "0.75rem" }}>
                        <div className="w-100 px-1 d-flex align-items-center justify-content-center rounded">

                            <a className="btnItem p-1 btn w-100 d-flex align-items-center justify-content-center" onClick={() => selectTabelaPreco.codTabPreco && dataCarrinho.findIndex((element) => 
                                ((element.codProduto === cores.idErpProduto + 'C' + cores.codCor) || (element.codProduto === cores.idErpProduto))) === -1 ? (setProdModal(cores), setModalShow(!modalShow)) : (setProdModal(cores), setAlertShow(true))}>
                                Adicionar  <span className="mx-1"> | </span>

                                <IoCubeOutline className="" />

                                {cores.qtd.toFixed(2)}
                            </a>
                        </div>
                    </td>
                </tr>
            </tbody>
        )
    }

    const setPage = (page) => {
        dispatch(getDispProd(select.idFilial, typeSearch, page))
    }

    const onClick = (value) => {
        if (value === 1) {
            settypeSearch({ ...typeSearch, tittle: "Código", type: 1 });
        } else if (value === 2) {
            settypeSearch({ ...typeSearch, tittle: "Descrição", type: 2 });
        }
    };

    const gradientBackground = {
        background: 'linear-gradient(to right, #f7941e, #cc7b17)'
    };

    const totItensCar = {
        padding: 0,
        margin: 0,
        top: '14px',
        position: 'absolute',
        fontSize: '12px'
    }

    const btnInc = {
        borderWidth: '1px',
        borderStyle: 'solid',
        fontWeight: 600,
        borderImage: 'linear-gradient(to right, #f7941e, #cc7b17) 1 / 1 / 0 stretch',
        color: '#f7941e',
        margin:'2px'
    };


    return (

        <div className="flex flex-col items-center h-screen mb-5">
            <h1>Incluir cotação</h1>
            <div className="flex flex-col w-full md:w-3/4 rounded-lg border p-3">
                {dataUser.length > 1 ? 
                    <SelectVendedor/>
                    :null
                }
                <div className="flex flex-row w-full">
                    {id ?
                        <FloatingLabel controlId="floatingInput" label="Cliente" className="my-2 w-full">
                            <Form.Control defaultValue={selectCli.nmCliente} aria-label="Disabled input example" readOnly />
                        </FloatingLabel>
                        :
                        <SelectCliente codVendedor={selectUser.IdErpUser} />
                    }
                </div>
                <div className="flex flex-col md:flex-row md:space-x-4">
                    <SelectCondPagamento/>
                    <FloatingLabel controlId="floatingInput" label="Tabela de preço" className="my-2 w-full">
                        <Form.Select onChange={e => dispatch(selectTabPreco(e.target.value))}>
                            <option value={0}>Selecione...</option>
                            {dataTabPreco.map((item) => {
                                return (
                                    <option className="text-sm" key={item.codTabPreco} value={JSON.stringify(item)} >{item.codTabPreco + ' - ' + item.nmTabPreco}</option>
                                )
                            })}
                        </Form.Select>
                    </FloatingLabel>
                </div>
                <div className="flex flex-col md:flex-row md:space-x-4">
                    <FloatingLabel controlId="floatingInput" label="Tipo Frete" className="my-2">
                        <Form.Select onChange={e => dispatch(selectTipoTransp(e.target.value))}>
                            <option value={""}>Selecione...</option>
                            <option className="text-sm" key={1} value={JSON.stringify({label:"CIF", value: "C"})}>CIF</option>
                            <option className="text-sm" key={2} value={JSON.stringify({label:"FOB", value: "F"})}>FOB</option>
                        </Form.Select>
                    </FloatingLabel>
                    <SelectTransportadora/>
                </div>
                <SelectRedespacho/>
                <FloatingLabel controlId="floatingInput" label="Pedido Cliente" className="my-2">
                    <Form.Control as="textarea" rows={3} maxLength="9" onChange={e => setPedCliente(e.target.value)} />
                </FloatingLabel>
                <FloatingLabel controlId="floatingInput" label="Observação" className="my-2">
                    <Form.Control as="textarea" rows={3} onChange={e => setObservacao(e.target.value)} />
                </FloatingLabel>
                <TotalCarrinho />
            </div>
            <><br /></>
            <div className="flex flex-col w-full md:w-3/4 rounded-lg border border-gray-300">
                <div className="flex flex-row justify-between m-3">
                    <h6><b>Produtos</b></h6>
                    <div className="flex flex-row rounded-lg border border-gray-300 align-bottom ml-1">
                        <Dropdown>
                            <Dropdown.Toggle variant="Secondary" id="dropdown-basic">
                                {typeSearch.tittle}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item eventKey={1} onClick={() => onClick(1)}>Código</Dropdown.Item>
                                <Dropdown.Item eventKey={2} onClick={() => onClick(2)}>Descrição</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        {/* <svg className="self-center w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg> */}
                        <input type="text" id="table-search" className=" outline-gray-300 p-2 pl-10 text-sm text-gray-900 bg-gray-50 rounded-r-lg" placeholder="pesquisar..." onChange={(event) => searchTerm(event.target.value, 0)} />
                    </div>
                </div>
                <Accordion flush className="w-full">
                    {initialProd.map((item, key) => {
                        return (
                            <Accordion.Item key={item.filial + item.idErpProduto} eventKey={item.filial + item.idErpProduto} onClick={() => getCor(select.idFilial, item.idErpProduto)}>
                                <Accordion.Header ><p className="text-sm mr-2 my-0">{item.idErpProduto + ' - ' + item.nmProduto}</p> </Accordion.Header>
                                <Accordion.Body style={{ overflow: "auto" }} >
                                    <table className="w-full" key={item.filial + item.idErpProduto}>
                                        <thead>
                                            <tr style={{ textAlign: "center" }}>
                                                <th rowspam="true" style={{ width: "20%", border: "1px solid #dee2e6", textAlign: "center", padding: "0.75rem" }}>Código cor</th>
                                                <th style={{ width: "60%", border: "1px solid #dee2e6", textAlign: "center", padding: "0.75rem" }}>Nome cor</th>
                                                <th style={{ width: "20%", border: "1px solid #dee2e6", textAlign: "center", padding: "0.75rem" }}>{estoque}</th>
                                            </tr>
                                        </thead>
                                        {cor.map((item1) => {
                                            return (
                                                <ItemCores cores={{ ...item1, ...item }} key={item1.idErpProduto + item1.codCor} />
                                            )
                                        })}
                                    </table>
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    })}
                </Accordion>
                <div className="flex flex-row justify-center m-5">
                    {page > 0 &&
                        // <button onClick={() => setPage(page - 1)} className=" bg-blue-400 border-2 border-blue-400 hover:border-blue-300 hover:bg-blue-300 py-1 px-1 rounded mx-1">
                        //     <b>Anterior</b>
                        // </button>
                        <Button variant="" style={btnInc} size="sm" className='w-100 px-3 d-flex align-items-center justify-content-center rounded btn' onClick={() => setPage(page - 1)}>
                            <IoRemove />
                            Anterior
                        </Button>
                    }
                    {hasNext &&
         
                        <Button variant="" style={btnInc} size="sm" className='w-100 px-3 d-flex align-items-center justify-content-center rounded btn' onClick={() => setPage(page + 1)}>
                            <IoAdd />
                            Próxima
                        </Button>
                    }
                </div>
            </div>
            <div className="fixed bottom-8 right-8 z-10">
                <button style={gradientBackground} className="bg-blue-500 text-white p-4 rounded-full flex items-center justify-center space-x-2" onClick={() => setModalShow1(!modalShow1)}>
                    <MdOutlineShoppingCart size='30' />
                    <span style={totItensCar} className="font-bold">{dataCarrinho.length}</span>
                </button>
            </div>
            {selectTabelaPreco.codTabPreco &&
                <AddCarrinho
                    show={modalShow}
                    onHide={() => setModalShow(!modalShow)}
                    item={prodModal}
                />}
            {<Modal show={alertShow} onHide={() => setAlertShow(false)} aria-labelledby="contained-modal-title-vcenter" centered backdrop="static">
                <Modal.Header closeButton className="bg-yellow-300">
                    {!selectTabelaPreco.codTabPreco && <Modal.Title>Tabela de preço não selecionada</Modal.Title>}
                    {selectTabelaPreco.codTabPreco && dataCarrinho.findIndex((element) => (element.codProduto === prodModal.idErpProduto + 'C' + prodModal.codCor) ||  (element.codProduto === prodModal.idErpProduto)) !== -1 && <Modal.Title>Produto já adicionado ao carrinho</Modal.Title>}
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setAlertShow(false)}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>}
            <ViewCarrinho
                show={modalShow1}
                onHide={() => setModalShow1(!modalShow1)}
                carrinho={dataCarrinho}
                dataorc={{observacao:observacao, pedCliente: pedCliente}}
            />
        </div>
    )
}


export default IncluirOrcamento