import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import Select, { components } from 'react-select';
import { selectTransp, getTransp } from '../../store/Transportadora';

const { ValueContainer, Placeholder } = components;

const SelectTransportadora = ({ initialValue }) => {
  const { optionsSelect } = useSelector(state => state.transpReduce);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTransp());
    if (initialValue) {
      dispatch(selectTransp(initialValue));
    }
  }, [dispatch, initialValue]);

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

  return (
    <div className="w-full">
      <Select
        defaultValue={optionsSelect.find((element) => element.value === initialValue)}
        onChange={(e) => e === null ? dispatch(selectTransp()) : dispatch(selectTransp(e.value))}
        isClearable={true}
        isSearchable={true}
        options={optionsSelect}
        components={{ ValueContainer: CustomValueContainer }}
        placeholder="Transportadora"
        styles={{
          container: (provided, state) => ({
            ...provided,
            marginTop: 10
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible",
            marginTop: 20
          }),
          placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            top: -15,
            backgroundColor: "transparent",
            fontSize: 14
          })
        }}
      />
    </div>
  );
}

export default SelectTransportadora;
