import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import Select, { components } from 'react-select';
import { selectVendedor, getOptVendedor } from '../../store/Usuarios';

const { ValueContainer, Placeholder } = components;

const SelectVendedor = () => {
  const { optionsSelect } = useSelector(state => state.userReduce);
  const [selectedValue, setSelectedValue] = useState(null); // State to hold the selected value
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOptVendedor());

    // Retrieve selected value from local storage
    const storedValue = localStorage.getItem('selectedVendedor');
    if (storedValue) {
      setSelectedValue(JSON.parse(storedValue));
    }
  }, [dispatch]);

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      dispatch(selectVendedor({idVendedor:selectedOption.value}));
      setSelectedValue(selectedOption); // Update selected value in state
      // Store selected value in local storage
      localStorage.setItem('selectedVendedor', JSON.stringify(selectedOption));
    }
  };

  return (
    <div className="w-full">
      <Select
        onChange={handleSelectChange}
        isClearable={true}
        isSearchable={true}
        options={optionsSelect}
        value={selectedValue} // Set selected value
        components={{
          ValueContainer: CustomValueContainer
        }}
        placeholder="Vendedor"
        styles={{
          container: (provided, state) => ({
            ...provided,
            marginTop: 10,
            minWidth: 300
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible",
            marginTop: 20
          }),
          placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            top: -15,
            backgroundColor: "transparent",
            fontSize: 14
          })
        }}
      />
    </div>
  );
}

export default SelectVendedor;